<script setup>
import { ref, defineProps, watch, defineExpose } from 'vue';

const props = defineProps({
    quantificacaoComplementarGhe: String
});

const quantificacaoComplementar = ref(props.quantificacaoComplementarGhe);

watch(
    () => props.quantificacaoComplementarGhe,
    (value) => {
        quantificacaoComplementar.value = value;
    }
);
defineExpose({
    quantificacaoComplementar
});
</script>
<template>
    <div class="flex flex-column mt-5">
        <span class="title font-semibold line-height-3">Quantificação complementar(GHE)</span>
        <div class="flex mt-3 grupo-radios">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <RadioButton v-model="quantificacaoComplementar" inputId="sim" name="sim" value="sim" />
                    <label for="sim" class="ml-2">Sim</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="quantificacaoComplementar" inputId="nao" name="nao" value="nao" />
                    <label for="nao" class="ml-2">Não</label>
                </div>
            </div>
        </div>
    </div>
</template>
