export const StatusGroGrupoRiscosElaboracaoEnum = Object.freeze({
    FISICO: 'Físico',
    QUIMICO: 'Químico',
    BIOLOGICO: 'Biológico',
    ERGONOMICO: 'Ergonômico',
    INESPECIFICO: 'Inespecífico',
    OUTRO: 'Outro',
    ACIDENTE_MECANICO: 'Acidente Mecânico'
});

export const styleMapStatusGrupoRiscos = {
    [StatusGroGrupoRiscosElaboracaoEnum.FISICO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.QUIMICO]: {
        backgroundColor: '#F69EBC',
        color: '#5D0C28'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.ACIDENTE_MECANICO]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.BIOLOGICO]: {
        backgroundColor: '#B4A08B',
        color: '#212121'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.ERGONOMICO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.INESPECIFICO]: {
        backgroundColor: '#9C27B0',
        color: '#FFFFFF'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.OUTRO]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.ACIDENTE_MECANICO]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    }
};
