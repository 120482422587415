const MedidaControle = Object.freeze([
    {
        label: 'EPC',
        value: 'EPC'
    },
    {
        label: 'EPI',
        value: 'EPI'
    },
    {
        label: 'Medida Administrativa',
        value: 'ADMINISTRATIVA'
    },
    {
        label: 'Medida de engenharia',
        value: 'ENGENHARIA'
    }
]);

const MedidaControleEnum = Object.freeze({
    ADMINISTRATIVA: 'ADMINISTRATIVA',
    ENGENHARIA: 'ENGENHARIA',
    EPC: 'EPC',
    EPI: 'EPI'
});

export default {
    MedidaControle,
    MedidaControleEnum
};
