<script setup>
import { defineProps, watch, ref } from 'vue';



const props = defineProps({
    criterioAvaliacao: Number
});

const criterioAvaliacaoModel = ref(props.criterioAvaliacao || null);

watch(() => props.criterioAvaliacao, (value) => {
    criterioAvaliacaoModel.value = value;
})
</script>
<template>
    <div class="grupo-criterio-avaliacao">
        <span class="title font-semibold line-height-3">Critério de avaliação</span>
        <div class="flex grupo-radios mt-3">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center">
                    <RadioButton disabled v-model="criterioAvaliacaoModel" inputId="quantitativo" name="criAvaliacao"
                        :value="1" />
                    <label for="quantitativo" class="ml-2">Quantitativo</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton disabled v-model="criterioAvaliacaoModel" inputId="qualitativo" name="criAvaliacao"
                        :value="2" />
                    <label for="qualitativo" class="ml-2">Qualitativo</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton disabled v-model="criterioAvaliacaoModel" inputId="faixaConforto" name="criAvaliacao"
                        :value="3" />
                    <label for="faixaConforto" class="ml-2">Faixa de conforto</label>
                </div>
            </div>
        </div>
    </div>
</template>
