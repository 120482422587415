<script setup>
import { computed, defineProps } from 'vue';
import GrupoRiscos, { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
import TipoAvaliacaoRisco from '../../../../enums/TipoAvaliacaoRisco';

const props = defineProps({
    risco: Object
});

const isQuantitativo = computed(() => props.risco.tipoAvaliacao === TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO);
const isQuantitativoQuimico = computed(
    () => props.risco.tipoAvaliacao === TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO && props.risco.grupoRisco === GrupoRiscos.QUIMICO
);
const isFaixaConforto = computed(() => props.risco.tipoAvaliacao === TipoAvaliacaoRisco.FAIXA_DE_CONFORTO);
const exibirValorTeto = computed(() => isQuantitativoQuimico.value && props.risco.temValorTeto);

const labelGrupoRisco = GrupoRiscosLabelEnum;
</script>
<template>
    <div v-if="props.risco" class="card-descricao">
        <div class="conteudo">
            <strong class="titulo">{{ props.risco.nome }}</strong>
            <div class="block-colunas">
                <div v-if="isQuantitativo" class="coluna-quantitativo">
                    <div class="dados">
                        <span>Nível de ação: </span> <strong> {{ props.risco.nivelAcao }}</strong>
                    </div>
                    <div class="dados">
                        <span>Limite de tolerância: </span> <strong> {{ props.risco.limiteTolerancia }}</strong>
                    </div>
                    <div v-if="exibirValorTeto" class="dados">
                        <span>Valor teto: </span> <strong> {{ props.risco.valorTeto }}</strong>
                    </div>
                </div>
                <div v-if="isFaixaConforto" class="coluna-faixa-conforto">
                    <div class="dados">
                        <span>Faixa de conforto mínima: </span> <strong>{{ props.risco.faixaConfortoMinima }}</strong>
                    </div>
                    <div class="dados">
                        <span>Faixa de conforto máxima: </span> <strong>{{ props.risco.faixaConfortoMaxima }}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="conteudo-grupo">
            <span
                >Grupo: <strong> {{ labelGrupoRisco.get(props.risco.grupoRisco) }}</strong></span
            >
        </div>
    </div>
</template>
<style scoped lang="scss">
.card-descricao {
    display: flex;

    justify-content: space-between;
    padding: 20px;
    margin: 10px 0 10px 0;
    border: 1px solid #ced4da;
    border-radius: 4px;

    .conteudo-grupo {
        display: flex;

        justify-content: flex-start;
    }
    .conteudo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .titulo {
            font-size: 17.5px;
            font-weight: 600px;
            line-height: 21.18px;
        }
        .block-colunas {
            display: flex;

            justify-content: space-between;
            margin-top: 10px;
            gap: 32px;
            .coluna-quantitativo {
                display: flex;

                justify-content: flex-start;
                flex-direction: column;

                .dados {
                    font-size: 14px;
                    line-height: 16.94px;
                    margin-bottom: 2px;
                }
            }
            .coluna-faixa-conforto {
                flex-direction: column;
                display: flex;
                align-items: left;
                justify-content: flex-start;
                .dados {
                    font-size: 14px;
                    line-height: 16.94px;
                    margin-bottom: 2px;
                }
            }
        }
    }
}
</style>
