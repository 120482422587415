<script setup>
import { ref, defineProps, computed, defineExpose, watchEffect } from 'vue';

import DropdownRiscoDescricaoRisco from '../../../descricao-risco/components/DropdownRiscoDescricaoRisco.vue';
import DropdownFonteGeradora from '../../../fonte-geradora/components/DropdownFonteGeradora.vue';
import DropdownFrequencia from '../../../inventario-riscos/components/checklist/checklist-perigo/DropdownFrequencia.vue';
import FormCardMedicao from './FormCardMedicao.vue';
import FormInputLesoesOuDanos from './FormInputLesoesOuDanos.vue';
import FormInputAcaoNecessaria from './FormInputAcaoNecessaria.vue';
import TipoAvaliacaoRisco from '../../../../enums/TipoAvaliacaoRisco';

const props = defineProps({
    risco: Object,
    aplicacaoPerigoDescricoesRiscos: Array,
    tipoAvaliacaoRisco: Number,
    hierarquiaId: Number
});

const aplicacaoPerigoDescricoesRiscos = ref([]);
const formInputAcaoNecessaria = ref();
const formInputLesoesOuDanos = ref();

watchEffect(() => {
    props.aplicacaoPerigoDescricoesRiscos?.forEach((item) => {
        aplicacaoPerigoDescricoesRiscos.value.push({
            id: item.id,
            riscoDescricaoRisco: {
                id: item.riscoDescricaoRiscoId,
                descricaoRisco: {
                    id: 0,
                    nome: ''
                }
            },
            possiveisLesoesDanosSaude: item.possiveisLesoesDanosSaude,
            acoesNecessarias: item.acoesNecessarias,
            aplicacaoPerigoFontesGeradoras: item.aplicacaoPerigoFontesGeradoras.map((fonte) => {
                return {
                    id: fonte.id,
                    fonteGeradora: {
                        id: fonte.fonteGeradoraId,
                        nome: ''
                    },
                    especificacaoFonteGeradora: fonte.especificacaoFonteGeradora,
                    frequencia: {
                        id: fonte.frequenciaId,
                        descricao: ''
                    },
                    aplicacaoPerigoFonteGeradoraMedicao: fonte.aplicacaoPerigoFonteGeradoraMedicao
                };
            })
        });
    });
});

const montarArrayAplicacaoPerigoFontesGeradoras = (fontesGeradoras) => {
    if (!fontesGeradoras) return;

    return fontesGeradoras.map((fonte) => {
        const aplicacaoPerigoFonteGeradoraMedicao = fonte.aplicacaoPerigoFonteGeradoraMedicao.length
            ? fonte.aplicacaoPerigoFonteGeradoraMedicao[0]
            : [];

        const {
            unidadeMedidaId,
            unidadeMedida,
            equipamentoId,
            equipamento,
            tempoExposicao,
            medicoesFonteGeradora,
            dataMedicao,
            responsavelMedicaoId,
            resposavelMedicao,
            medicaoNaoExecutada,
            justificativaFaltaMedicao,
            payloadMedicao,
            id
        } = aplicacaoPerigoFonteGeradoraMedicao;

        return {
            id: fonte.id,
            fonteGeradoraId: fonte.fonteGeradora?.id,
            especificacao: fonte.especificacaoFonteGeradora,
            frequenciaId: fonte.frequencia?.id,
            tipoAvaliacaoRisco: props.tipoAvaliacaoRisco,
            aplicacaoMedicao:
                props.tipoAvaliacaoRisco === TipoAvaliacaoRisco.CRITERIO_QUALITATIVO
                    ? null
                    : {
                          id: id,
                          tipoRisco: props.risco.tipoRisco,
                          hierarquiaId: props.hierarquiaId,
                          unidadeMedidaId: unidadeMedidaId ?? unidadeMedida?.id,
                          equipamentoId: equipamentoId ?? equipamento?.id,
                          tempoExposicao: tempoExposicao,
                          medicoesFonteGeradora,
                          dataMedicao: dataMedicao,
                          responsavelMedicaoId: responsavelMedicaoId ?? resposavelMedicao?.id,
                          medicaoNaoExecutada: medicaoNaoExecutada ?? false,
                          justificativaFaltaMedicao: justificativaFaltaMedicao,
                          payloadMedicao: payloadMedicao ?? {}
                      }
        };
    });
};

const payload = computed(() => {
    return aplicacaoPerigoDescricoesRiscos.value.map((item) => {
        const aplicacaoPerigoFontesGeradoras = montarArrayAplicacaoPerigoFontesGeradoras(item.aplicacaoPerigoFontesGeradoras);

        return {
            id: item?.id,
            riscoDescricaoRiscoId: item.riscoDescricaoRisco?.id,
            possiveisLesoesDanosSaude: formInputLesoesOuDanos.value?.possiveisLesoesDanosSaude,
            acoesNecessarias: formInputAcaoNecessaria.value?.inputAcoesNecessarias.map((acao) => acao.value),
            aplicacaoPerigoFontesGeradoras: aplicacaoPerigoFontesGeradoras
        };
    });
});

function removeFonteCircunstancia(indexDescricaoRisco, index) {
    aplicacaoPerigoDescricoesRiscos.value[indexDescricaoRisco].aplicacaoPerigoFontesGeradoras.splice(index, 1);
}

function receberMedicao({ index, indexDescricaoRisco, medicao }) {
    if (aplicacaoPerigoDescricoesRiscos.value[indexDescricaoRisco].aplicacaoPerigoFontesGeradoras[index].aplicacaoPerigoFonteGeradoraMedicao[0]) {
        aplicacaoPerigoDescricoesRiscos.value[indexDescricaoRisco].aplicacaoPerigoFontesGeradoras[index].aplicacaoPerigoFonteGeradoraMedicao[0] =
            medicao;
    } else {
        aplicacaoPerigoDescricoesRiscos.value[indexDescricaoRisco].aplicacaoPerigoFontesGeradoras[index].aplicacaoPerigoFonteGeradoraMedicao = [
            medicao
        ];
    }
}

function addAplicacaoPerigoDescricoesRiscosFonteGeradora(index) {
    aplicacaoPerigoDescricoesRiscos.value[index].aplicacaoPerigoFontesGeradoras.push({
        fonteGeradora: null,
        especificacaoFonteGeradora: null,
        frequencia: null,

        aplicacaoPerigoFonteGeradoraMedicao: [
            {
                medicao: null,
                dataMedicao: null
            }
        ]
    });
}

function addAplicacaoPerigoDescricoesRiscos() {
    aplicacaoPerigoDescricoesRiscos.value.push({
        riscoDescricaoRisco: null,
        possiveisLesoesDanosSaude: null,
        acoesNecessarias: null,
        aplicacaoPerigoFontesGeradoras: [
            {
                fonteGeradora: null,
                especificacaoFonteGeradora: null,
                frequencia: null,
                possiveisLesoesDanosSaude: null,
                acoesNecessarias: null,
                aplicacaoPerigoFonteGeradoraMedicao: [
                    {
                        medicao: null,
                        dataMedicao: null
                    }
                ]
            }
        ]
    });
}

function removeAplicacaoPerigoDescricoesRiscos(index) {
    aplicacaoPerigoDescricoesRiscos.value.splice(index, 1);
}

defineExpose({ payload });
</script>

<template>
    <div class="flex flex-column w-full mt-5 mb-5">
        <Panel
            toggleable
            header="Descrição de risco"
            class="flex flex-column w-full mt-4"
            v-for="(input, indexDescricaoRisco) in aplicacaoPerigoDescricoesRiscos"
            :key="indexDescricaoRisco"
        >
            <div class="flex flex-column w-full">
                <DropdownRiscoDescricaoRisco
                    v-model="input.riscoDescricaoRisco"
                    :idValue="input.riscoDescricaoRisco?.id"
                    :filtrosExtras="{ riscoId: props.risco.id }"
                />
            </div>
            <div class="flex flex-column mt-4">
                <div class="flex flex-column" v-for="(inputFontes, index) in input.aplicacaoPerigoFontesGeradoras" :key="index">
                    <div class="grid flex align-items-center">
                        <div class="flex flex-column mr-2 col">
                            <label class="mb-2" for="descricaoRisco">Fonte geradora</label>

                            <DropdownFonteGeradora v-model="inputFontes.fonteGeradora" :idValue="inputFontes.fonteGeradora?.id" />
                        </div>
                        <div class="flex flex-column mr-2 col">
                            <label class="mb-2" for="descricaoRisco">Especificação</label>
                            <InputText type="text" v-model="inputFontes.especificacaoFonteGeradora" class="w-full" />
                        </div>
                        <div class="flex flex-column mr-2 col">
                            <label class="mb-2" for="descricaoRisco">Frequência</label>
                            <DropdownFrequencia v-model="inputFontes.frequencia" :idValue="inputFontes.frequencia?.id" />
                        </div>
                        <div v-if="tipoAvaliacaoRisco !== TipoAvaliacaoRisco.CRITERIO_QUALITATIVO" class="col flex-column">
                            <FormCardMedicao
                                @receberMedicao="receberMedicao"
                                :index="index"
                                :indexDescricaoRisco="indexDescricaoRisco"
                                :risco="risco"
                                :aplicacaoPerigoFonteGeradoraMedicao="inputFontes.aplicacaoPerigoFonteGeradoraMedicao[0]"
                            />
                        </div>
                    </div>
                    <Button
                        class="button-salvar p-button p-button-outlined w-15rem"
                        text
                        v-if="index > 0"
                        icon="pi pi-times"
                        label="Remover fonte"
                        @click="removeFonteCircunstancia(indexDescricaoRisco, index)"
                    />
                </div>
                <div class="mt-4">
                    <Button
                        class="wp-button p-button-outlined w-15rem"
                        type="button"
                        icon="pi pi-plus"
                        label="Adicionar fonte"
                        @click="addAplicacaoPerigoDescricoesRiscosFonteGeradora(indexDescricaoRisco)"
                    />
                </div>
            </div>

            <FormInputLesoesOuDanos ref="formInputLesoesOuDanos" :lesoesOuDanos="input?.possiveisLesoesDanosSaude" />

            <FormInputAcaoNecessaria ref="formInputAcaoNecessaria" :acoesNecessarias="input?.acoesNecessarias" />

            <Button
                class="button-salvar p-button p-button-outlined w-20rem mt-4"
                text
                v-if="indexDescricaoRisco > 0"
                icon="pi pi-times"
                label="Remover descrição de risco"
                @click="removeAplicacaoPerigoDescricoesRiscos(indexDescricaoRisco)"
            />
        </Panel>
        <div class="mt-5 flex justify-content-start">
            <Button
                class="button-salvar p-button p-button-outlined mt-3"
                type="button"
                icon="pi pi-plus"
                label="Adicionar descrição de risco"
                @click="addAplicacaoPerigoDescricoesRiscos"
            />
        </div>
    </div>
</template>
7
