<script setup>
import { ref, defineExpose, defineProps, watch } from 'vue';

import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const props = defineProps({
    recomendacaoOrientacao: String
});

const recomendacaoOrientacao = ref(props.recomendacaoOrientacao ?? '');

const rules = {
    recomendacaoOrientacao: {
        required: helpers.withMessage('Observação é obrigatória', required)
    }
};

const valid = useVuelidate(rules, { recomendacaoOrientacao });

watch(
    () => props.recomendacaoOrientacao,
    (value) => {
        recomendacaoOrientacao.value = value;
    }
);

defineExpose({ valid, recomendacaoOrientacao });
</script>
<template>
    <div class="w-full mt-5">
        <div class="flex flex-column w-full">
            <label class="mb-2" for="descricaoRisco">Observação</label>
            <InputText
                :class="{ 'p-invalid': valid.recomendacaoOrientacao.$errors.length && valid.recomendacaoOrientacao.lazy.$dirty }"
                v-model="valid.recomendacaoOrientacao.$model"
                class="w-full input-large"
            />
            <small v-if="valid.recomendacaoOrientacao.$errors.length && valid.recomendacaoOrientacao.lazy.$dirty" class="p-error">
                {{ valid.recomendacaoOrientacao.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>
