<script setup>
import { defineProps, defineEmits, computed, onBeforeMount, ref } from 'vue';
import SesmtService from '../../../../services/SesmtService';

const props = defineProps({
    modelValue: Object,
    itemSize: {
        type: Number,
        default: 20
    },
    idValue: Number,
    filtrosExtras: Object
});

const emits = defineEmits(['update:modelValue']);

const $service = ref();

const value = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue);
    }
});
onBeforeMount(() => {
    $service.value = new SesmtService('/matriz-config');
});
</script>
<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        :filterFields="['nome']"
        optionLabel="Nome"
        placeholder="Selecione a matriz..."
        :service="$service"
        :itemSize="itemSize"
        :renderValue="(slotProps) => slotProps.value?.nome"
        :renderOption="(slotProps) => slotProps.option?.nome"
        :filtrosExtras="filtrosExtras"
    >
    </AppOnePageDropdown>
</template>
