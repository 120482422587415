<script setup>
import { computed, defineProps, defineEmits, ref, reactive } from 'vue';
import { useMoment } from '../../../../composables/useMoment';
import { useDialog } from 'primevue/usedialog';
import FormMedicaoItem from './FormMedicaoItem.vue';
import AppConfirmDialog from '../../../../components/AppConfirmDialog.vue';
import GrupoRiscos from '../../../../enums/GrupoRiscos';

const { formatDateTz } = useMoment();

const emit = defineEmits(['update:medicoesFonteGeradora']);

const props = defineProps({
    medicoesFonteGeradora: {
        type: Array,
        default: () => []
    },
    risco: {
        type: Object,
        required: true
    },
    unidadeMedida: {
        type: Object,
        default: () => {}
    }
});

const casasDecimais = computed(() => {
    const DUAS_CASAS_DECIMAIS = 2;
    const QUATRO_CASAS_DECIMAIS = 4;
    return props.risco.grupoRisco === GrupoRiscos.QUIMICO ? QUATRO_CASAS_DECIMAIS : DUAS_CASAS_DECIMAIS;
});

const medicoes = computed({
    get: () => {
        return [...props.medicoesFonteGeradora];
    },
    set: (value) => {
        emit('update:medicoesFonteGeradora', value);
    }
});

const menu = ref([]);
const toggleMenu = async (event, index) => menu.value?.[index].toggle(event);
const getActionItems = (record, index) => {
    return [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => {
                openDialog(record, index);
            }
        },
        {
            label: 'Excluir',
            icon: 'pi pi-trash',
            command: () => confirmacaoDeleteDialog.abrir(index)
        }
    ];
};

const dialogMedicaoItem = useDialog();
function openDialog(medicao, index) {
    dialogMedicaoItem.open(FormMedicaoItem, {
        props: {
            header: 'Medição de risco',
            style: {
                width: '30rem'
            },
            modal: true
        },
        data: {
            risco: props.risco,
            medicao,
            index,
            casasDecimais: casasDecimais.value
        },
        emits: {
            onSave: (dadosMedicao) => {
                const copiaMedicoes = medicoes.value;

                if (!dadosMedicao.index && typeof dadosMedicao.index !== 'number') {
                    copiaMedicoes.push({
                        descricao: dadosMedicao.form.descricao,
                        medicao: dadosMedicao.form.medicao,
                        dataMedicao: dadosMedicao.form.dataMedicao
                    });

                    medicoes.value = copiaMedicoes;

                    return;
                }

                copiaMedicoes[dadosMedicao.index] = {
                    id: dadosMedicao.form.id,
                    descricao: dadosMedicao.form.descricao,
                    medicao: dadosMedicao.form.medicao,
                    dataMedicao: dadosMedicao.form.dataMedicao
                };

                medicoes.value = copiaMedicoes;
            }
        },
        onClose: () => {}
    });
}

const indexDelete = ref();
const confirmacaoDeleteDialog = reactive({
    voltar: () => {
        indexDelete.value = undefined;
        confirmacaoDeleteDialog.aberta = false;
    },
    abrir: (index) => {
        indexDelete.value = index;
        confirmacaoDeleteDialog.aberta = true;
    },
    confirmar: async () => {
        removerMedicao();
        indexDelete.value = undefined;
        confirmacaoDeleteDialog.aberta = false;
    },
    aberta: false
});

function removerMedicao() {
    const copiaMedicoes = medicoes.value;
    copiaMedicoes.splice(indexDelete.value, 1);
    medicoes.value = copiaMedicoes;
}

const customLabelUnidadeMedida = computed(() => {
    if (!props.unidadeMedida.simbolo) {
        return props.unidadeMedida.descricao;
    }

    return `(${props.unidadeMedida.simbolo})`;
});
</script>
<template>
    <div class="flex flex-column">
        <span class="font-bold">Registro de medições</span>
        <DataTable :value="medicoes" class="mt-3" responsiveLayout="scroll">
            <template #empty>Nenhum registro encontrado</template>
            <Column style="width: 55px">
                <template #body="{ index }">
                    {{ index + 1 }}
                </template>
            </Column>
            <Column field="descricao" header="Descrição" />
            <Column field="medicao" header="Medição">
                <template #body="{ data }">
                    {{ `${data.medicao.toFixed(casasDecimais)} ${props.unidadeMedida?.id ? customLabelUnidadeMedida : ''}`
                    }}<sup v-if="props.unidadeMedida?.id && props.unidadeMedida?.possuiExponenciacao">{{
                        props.unidadeMedida.valorExponenciacao
                    }}</sup>
                </template>
            </Column>
            <Column field="dataMedicao" header="Data">
                <template #body="{ data }">
                    {{ data.dataMedicao ? formatDateTz(data.dataMedicao) : 'Não informado' }}
                </template>
            </Column>
            <Column field="acoes" style="width: 55px">
                <template #body="{ data, index }">
                    <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, index)" />
                    <Menu :ref="(el) => (menu[index] = el)" :model="getActionItems(data, index)" :popup="true" />
                </template>
            </Column>
        </DataTable>
    </div>
    <Button label="Nova medição" icon="pi pi-plus" class="p-button-text mt-3" rounded @click="openDialog" />
    <AppConfirmDialog
        v-model:visible="confirmacaoDeleteDialog.aberta"
        confirmLabel="Confirmar"
        title="Deseja excluir esta medição?"
        :loading="false"
        @onConfirm="confirmacaoDeleteDialog.confirmar"
        @onClose="confirmacaoDeleteDialog.voltar"
    />
</template>
