<script setup>
import { defineEmits, computed, inject, ref } from 'vue';
import GrupoRiscos from '../../../../enums/GrupoRiscos';
import TipoRiscos from '../../../../enums/TipoRiscos';
import AppInputTextLimitado from '@/components/input/AppInputTextLimitado.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const emit = defineEmits(['save']);
const dialogMedicaoItemRef = inject('dialogRef');

const form = ref();
atribuirCampos();

function atribuirCampos() {
    form.value = {
        ...dialogMedicaoItemRef.value.data.medicao
    };
    form.value.dataMedicao = formatarData(form.value.dataMedicao);
}

function formatarData(data) {
    if (!data) return new Date();

    return new Date(data);
}

const risco = computed(() => dialogMedicaoItemRef.value.data.risco);

const fractionDigits = computed(() => {
    const DUAS_CASAS_DECIMAIS = 2;
    const QUATRO_CASAS_DECIMAIS = 4;
    return risco.value.grupoRisco === GrupoRiscos.QUIMICO ? QUATRO_CASAS_DECIMAIS : DUAS_CASAS_DECIMAIS;
});

const valorMinimoParaTipoRiscoFrio = computed(() => (risco.value.tipoRisco === TipoRiscos.FRIO ? null : 0));

const LIMITE_MINIMO_CARACTER = 3;
const LIMITE_MAXIMO_CARACTER = 100;
const rules = {
    descricao: {
        required: helpers.withMessage('Campo obrigatório.', required),
        validacaoPersonalizadaDescricao: helpers.withMessage('O campo contém limitações de caracteres.', (value) => {
            return value.length >= LIMITE_MINIMO_CARACTER && value.length <= LIMITE_MAXIMO_CARACTER;
        }),
        lazy: true
    },
    medicao: {
        required: helpers.withMessage('Campo obrigatório.', required),
        lazy: true
    }
};

const v$ = useVuelidate(rules, form);

async function confirmar() {
    const isValid = await v$.value.$validate();

    if (!isValid) return;

    const dadosMedicao = {
        form: form.value,
        index: dialogMedicaoItemRef.value.data?.index
    };
    emit('save', dadosMedicao);
    dialogMedicaoItemRef.value.close();
}
</script>
<template>
    <div class="grid">
        <div class="field col-12 mb-0 pb-0">
            <AppInputTextLimitado
                placeholder="Descrição"
                v-model="v$.descricao.$model"
                :tamanhoMaximo="LIMITE_MAXIMO_CARACTER"
                :mensagemErro="v$.descricao.$errors.length && v$.descricao.lazy.$dirty ? v$.descricao.$errors[0]?.$message : ''"
                :invalido="Boolean(v$.descricao.$errors.length && v$.descricao.lazy.$dirty)"
            />
        </div>
        <div class="field col-12 pb-0">
            <label for="medicao">Medição</label>
            <InputNumber
                id="medicao"
                v-model="v$.medicao.$model"
                mode="decimal"
                :minFractionDigits="fractionDigits"
                :maxFractionDigits="fractionDigits"
                :min="valorMinimoParaTipoRiscoFrio"
                class="w-full"
                :class="{ 'p-invalid': v$.medicao.$errors.length && v$.medicao.lazy.$dirty }"
            />
            <small v-if="v$.medicao.$errors.length && v$.medicao.lazy.$dirty" class="p-error">
                {{ v$.medicao.$errors[0].$message }}
            </small>
        </div>
        <div class="field col-12 pb-0">
            <label for="dataMedicao">Data da medição</label>
            <Calendar id="dataMedicao" class="w-full" v-model="form.dataMedicao" dateFormat="dd/mm/yy" showIcon disabled />
        </div>
    </div>
    <div class="flex flex-row gap-3 justify-content-end">
        <Button class="p-button-text" label="Cancelar" @click="dialogMedicaoItemRef.close" />
        <Button label="Confirmar" @click="confirmar" />
    </div>
</template>
