<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { useRouter } from 'vue-router';
import OrigemAnexoEnum from '../../enums/OrigemAnexoEnum';
import { getClientSesmt } from '../../services/http-sesmt';

import HeaderPanel from './components/HeaderPanel';
import FormRegistrarPendencia from './components/FormRegistrarPendencia.vue';
import FormCaracterizarMedidasControle from './components/form-caracterizacao/FormCaracterizarMedidasControle.vue';
import BaseService from '../../services/BaseService';
import FormCaracterizarExposicao from './components/form-caracterizacao/FormCaracterizarExposicao.vue';
import FormCriterioAvaliacao from './components/form-caracterizacao/FormCriterioAvaliacao.vue';
import FormCaracterizarMatrizAvaliacao from './components/form-caracterizacao/FormCaracterizarMatrizAvaliacao.vue';
import FormInputAnaliseQualitativa from './components/form-caracterizacao/FormInputAnaliseQualitativa.vue';
import FormCaracterizarDescricaoRisco from './components/form-caracterizacao/FormCaracterizarDescricaoRisco.vue';
import FormInputQuantificacaoComplementar from './components/form-caracterizacao/FormInputQuantificacaoComplementar.vue';
import FormInputRecomendacaoOrientacao from './components/form-caracterizacao/FormInputRecomendacaoOrientacao.vue';

import { useAlert } from '../../composables/useAlert';
import AnexarArquivos from './components/AnexarArquivos.vue';
import TipoAnexoEnum from '../../enums/TipoAnexoEnum';
import PgrElaboracaoPendenciaOrigemEnum from '../../enums/PgrElaboracaoPendenciaOrigemEnum';

const { currentRoute, go } = useRouter();
const { alertSucess, alertError } = useAlert();

const customer = ref({});
const aplicacaoRespostaPerigoId = ref();
const pendencias = ref();
const respostaPerigoId = ref();
const risco = ref(null);
const descricaoHierarquia = ref({});
const loading = ref(false);
const caracterizar = ref();
const formCaracterizarExposicao = ref();
const formCaracterizarDescricaoRisco = ref();
const formCaracterizarMatrizAvaliacao = ref();
const formInputAnaliseQualitativa = ref();
const formInputQuantificacaoComplementar = ref();
const formCaracterizarMedidasControle = ref();
const formInputRecomendacaoOrientacao = ref();

const temPendencia = ref();

watch(
    () => pendencias.value?.pendencias,
    () => {
        temPendencia.value = pendencias.value?.pendencias.length;
    }
);

async function montarPayloadCaracterizar() {
    const isValidFormCaracterizarExposicao = await formCaracterizarExposicao.value?.validExposicao.$validate();

    if (!isValidFormCaracterizarExposicao) return;
    await formCaracterizarMedidasControle.value?.obterPayloadPainelMedidasControle();
    const pgrElaboracaoId = currentRoute.value.params.idElaboracao;

    return {
        pgrElaboracaoId,
        aplicacaoRespostaPerigoId: aplicacaoRespostaPerigoId.value,
        aplicacaoRespostaPerigoVersao: caracterizar.value?.versao,
        grupoRisco: caracterizar.value?.risco.grupoRisco,
        exposicaoAtiva: formCaracterizarExposicao?.value.payload.exposicaoAtiva,
        dataFim: formCaracterizarExposicao?.value.payload.dataFim,
        tipoAvaliacaoRisco: caracterizar.value?.tipoAvaliacaoRisco,
        aplicacaoPerigoDescricoesRiscos: formCaracterizarDescricaoRisco.value?.payload,
        matrizRisco: formCaracterizarMatrizAvaliacao.value?.payload,
        analiseQualitativa: formInputAnaliseQualitativa.value?.analiseQualitativa,
        quantificacaoComplementarGhe: formInputQuantificacaoComplementar.value?.quantificacaoComplementar,
        aplicacaoPerigoMedidasControle: formCaracterizarMedidasControle.value?.aplicacaoPerigoMedidasControlePayload,
        recomendacaoOrientacao: formInputRecomendacaoOrientacao.value?.recomendacaoOrientacao
    };
}

async function salvar() {
    const payload = await montarPayloadCaracterizar();

    if (!payload) return;

    try {
        loading.value = true;
        await getClientSesmt().post('aplicacao-pgr-elaboracao/caracterizacao/salvar-validar', payload);
        go(-1);

        alertSucess('Caracterização salva com sucesso!');
    } catch (error) {
        const message = error?.response?.data?.details?.response?.message[0];
        alertError(`Erro ao salvar! ${message}`);
    } finally {
        loading.value = false;
    }
}

async function getCustomer(customerId) {
    const { data } = await new BaseService('/customers').findById(customerId);
    customer.value = data;
}

async function load() {
    try {
        loading.value = true;
        aplicacaoRespostaPerigoId.value = currentRoute.value.params.respostaperigoId;

        const { data } = await getClientSesmt().get(`aplicacao-pgr-elaboracao/caracterizacao/${aplicacaoRespostaPerigoId.value}`);
        caracterizar.value = data;
    } catch (error) {
        const message = error?.response?.data?.details?.response?.message[0];
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
}

async function init() {
    risco.value = null;
    descricaoHierarquia.value.setor = currentRoute.value.params.setor;
    descricaoHierarquia.value.cargo = currentRoute.value.params.cargo;
    respostaPerigoId.value = currentRoute?.value.params?.respostaperigoId;

    const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;

    await getCustomer(customerId);

    await load();
}

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Formulário de Caracterização" :title="'Caracterização'" nomeTelaDoManual="customer-caracterizacao-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                    <div class="descricoes flex flex-column">
                        <div class="setor">
                            <span class="font-semibold line-height-3 cursor-pointer nome-cliente">Setor</span>
                            <span class="ml-2"> {{ descricaoHierarquia?.setor }}</span>
                        </div>
                        <div class="cargo">
                            <span class="font-semibold line-height-3 cursor-pointer nome-cliente">Cargo</span>
                            <span class="ml-2"> {{ descricaoHierarquia?.cargo }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <form class="block-form">
            <FormCaracterizarExposicao
                :grupoRisco="caracterizar?.risco.grupoRisco"
                :nomeRisco="caracterizar?.risco.nome"
                :dataInicio="caracterizar?.dataInicio"
                :dataFim="caracterizar?.dataFim"
                :exposicaoAtiva="caracterizar?.exposicaoAtiva"
                ref="formCaracterizarExposicao"
            />

            <FormCriterioAvaliacao :criterioAvaliacao="caracterizar?.tipoAvaliacaoRisco" />

            <FormCaracterizarDescricaoRisco
                ref="formCaracterizarDescricaoRisco"
                :risco="caracterizar?.risco"
                :aplicacaoPerigoDescricoesRiscos="caracterizar?.aplicacaoPerigoDescricoesRiscos"
                :tipoAvaliacaoRisco="caracterizar?.tipoAvaliacaoRisco"
                :tipoRisco="caracterizar?.risco.nome"
                :hierarquiaId="caracterizar?.inventarioHierarquia.hierarquiaId"
            />

            <FormCaracterizarMatrizAvaliacao
                :matrizConfig="caracterizar?.matrizConfig"
                :matrizConfigLinhaEixoX="caracterizar?.matrizConfigLinhaEixoX"
                :matrizConfigLinhaEixoY="caracterizar?.matrizConfigLinhaEixoY"
                :matrizConfigLinhaEixoR="caracterizar?.matrizConfigLinhaEixoR"
                ref="formCaracterizarMatrizAvaliacao"
            />

            <FormInputAnaliseQualitativa ref="formInputAnaliseQualitativa" :analiseQualitativa="caracterizar?.analiseQualitativa" />

            <FormInputQuantificacaoComplementar
                ref="formInputQuantificacaoComplementar"
                :quantificacaoComplementarGhe="caracterizar?.quantificacaoComplementarGhe"
            />

            <FormCaracterizarMedidasControle
                ref="formCaracterizarMedidasControle"
                :aplicacaoPerigoMedidasControle="caracterizar?.aplicacaoPerigoMedidasControle"
            />

            <FormInputRecomendacaoOrientacao :recomendacaoOrientacao="caracterizar?.recomendacaoOrientacao" ref="formInputRecomendacaoOrientacao" />

            <AnexarArquivos
                class="mt-5"
                :origemId="caracterizar?.id"
                :origem="OrigemAnexoEnum.CHECKLIST_PERIGO"
                :tipo="TipoAnexoEnum.CHECKLIST_PERIGO"
            />

            <FormRegistrarPendencia
                class="mt-5"
                v-if="caracterizar?.id"
                ref="pendencias"
                :origemId="caracterizar?.id"
                :origem="PgrElaboracaoPendenciaOrigemEnum.PERIGO"
            />
            <div class="flex justify-content-end align-items-center mt-4">
                <Button class="button-link mb-3" text label="Cancelar" @click="go(-1)" />
                <Button
                    class="ml-5 button-salvar"
                    type="button"
                    :loading="loading"
                    :label="temPendencia ? 'Salvar' : 'Salvar e validar'"
                    @click="salvar"
                />
            </div>
        </form>
    </div>
</template>
