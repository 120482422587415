<script setup>
import { ref, defineExpose, defineProps, watch } from 'vue';

import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const props = defineProps({
    analiseQualitativa: String
});

const analiseQualitativa = ref('');

const rules = {
    analiseQualitativa: {
        required: helpers.withMessage('Uma análise qualitativa é obrigatória', required)
    }
};

const valid = useVuelidate(rules, { analiseQualitativa });

watch(
    () => props.analiseQualitativa,
    (value) => {
        analiseQualitativa.value = value;
    }
);

defineExpose({ valid, analiseQualitativa });
</script>
<template>
    <div class="w-full mt-4">
        <div class="flex flex-column w-full">
            <label class="mb-2" for="descricaoRisco">Análise qualitativa</label>
            <InputText
                :class="{ 'p-invalid': valid.analiseQualitativa.$errors.length }"
                v-model="valid.analiseQualitativa.$model"
                class="w-full input-large"
            />
            <small v-if="valid.analiseQualitativa.$errors.length" class="p-error">
                {{ valid.analiseQualitativa.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>
