<script setup>
import { ref, defineProps, watch, computed, defineExpose, onMounted } from 'vue';
import OrigemAnexoEnum from '../../../../../enums/OrigemAnexoEnum';
import TipoAnexoEnum from '../../../../../enums/TipoAnexoEnum';
import AnexarArquivos from '../../AnexarArquivos.vue';
import DropdownEPI from './DropdownEPI.vue';
import AppInputTextLimitado from '@/components/input/AppInputTextLimitado.vue';
import MedidaControle from '../../../../../enums/MedidaControleEnum';

const props = defineProps({
    epiId: Number,
    descricao: String,
    id: Number,
    dataInclusao: String,
    certificado: Number
});

const { MedidaControleEnum } = MedidaControle;
const epi = ref();
const certificadoAprovacao = ref();
const anexarArquivos = ref();

watch(
    () => props.descricao,
    () => {
        epi.value = {
            id: props.epiId,
            nome: '',
            descricao: ''
        };
    }
);

watch(
    () => props.certificado,
    (newValue) => {
        certificadoAprovacao.value = newValue;
    }
);

const payloadEPI = computed(() => {
    return {
        id: props.id,
        tipo: MedidaControleEnum.EPI,
        epiId: epi.value?.id,
        descricao: epi.value?.descricao,
        certificadoAprovacao: certificadoAprovacao.value,
        anexosTemporarios: anexarArquivos.value?.anexosTemporarios,
        dataInclusao: props.dataInclusao ?? new Date()
    };
});

onMounted(() => (certificadoAprovacao.value = props.certificado));

defineExpose({
    payloadEPI
});
</script>
<template>
    <div class="flex flex-column w-full">
        <div class="w-full mt-4">
            <div class="flex flex-column w-full">
                <label class="mb-2" for="nomeDoEpi">Nome do EPI</label>
                <DropdownEPI id="nomeDoEpi" :idValue="props.epiId" v-model="epi" />
            </div>
        </div>

        <div v-if="epi?.descricao" class="w-full mt-4">
            <div class="flex flex-column w-full">
                <AppInputTextLimitado placeholder="Descrição" v-model="epi.descricao" />
            </div>
        </div>

        <div class="w-full mt-4">
            <div class="flex flex-column w-full">
                <label class="mb-2" for="certificadoAprovacao">Certificado de aprovação</label>
                <InputNumber id="certificadoAprovacao" v-model="certificadoAprovacao" class="w-full input-large" />
            </div>
        </div>

        <div class="w-full mt-6">
            <AnexarArquivos
                class="mt-5"
                ref="anexarArquivos"
                :habilitarAnexoTemporario="!props?.id"
                :origemId="props?.id"
                :origem="OrigemAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE"
                :tipo="TipoAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE"
            />
        </div>
    </div>
</template>
