<script setup>
import { ref, computed, watch, defineProps, defineExpose } from 'vue';
import { getClientSesmt } from '../../../../services/http-sesmt';
import { useAlert } from '../../../../composables/useAlert';

import DropdownMatrizes from './DropdownMatrizes.vue';

const { alertError } = useAlert();

const props = defineProps({
    matrizConfig: Object,
    matrizConfigLinhaEixoX: Object,
    matrizConfigLinhaEixoY: Object,
    matrizConfigLinhaEixoR: Object
});

const matrizes = ref(null);
const loadingMatriz = ref(false);
const matrizConfig = ref(null);
const eixoX = ref();
const eixoR = ref();
const eixoY = ref();
const eixosX = ref();
const eixosY = ref();
const eixosR = ref();

const calculandoRisco = computed(() =>
    eixosR.value?.find((r) => r.posicaoEixoY === eixoY.value?.posicaoEixoY && r.posicaoEixoX === eixoX.value?.posicaoEixoX)
);

watch(
    () => props.matrizConfig,
    () => {
        matrizes.value = props.matrizConfig;
    }
);

function loadMatrizConfigLinhasEixos() {
    if (props.matrizConfigLinhaEixoX) {
        eixoX.value = eixosX.value?.find((e) => e.id === props.matrizConfigLinhaEixoX.id);
    }

    if (props.matrizConfigLinhaEixoY) {
        eixoY.value = eixosY.value?.find((e) => e.id === props.matrizConfigLinhaEixoY.id);
    }

    if (props.matrizConfigLinhaEixoR) {
        eixoR.value = eixosR.value?.find((e) => e.id === props.matrizConfigLinhaEixoR.id);
    }
}

watch(
    () => matrizes.value,
    async (newValue) => {
        if (newValue !== null) {
            await loadMatrizConfig(matrizes.value.id);
        } else {
            matrizConfig.value = null;
            eixosY.value = null;
            eixosX.value = null;
            eixosR.value = null;
            eixoY.value = null;
            eixoX.value = null;
            eixoR.value = null;
        }
    }
);

const payload = computed(() => {
    return {
        id: matrizConfig.value?.matrizConfig.id,
        eixoXId: eixoX.value?.id,
        eixoYId: eixoY.value?.id,
        eixoRId: calculandoRisco.value?.id
    };
});

function definindoLinhas(eixos) {
    eixosY.value = eixos.filter((e) => e.eixo === 'y');
    eixosX.value = eixos.filter((e) => e.eixo === 'x');
    eixosR.value = eixos.filter((e) => e.eixo === 'r');

    loadMatrizConfigLinhasEixos();
}
async function loadMatrizConfig(id) {
    try {
        loadingMatriz.value = true;
        const { data } = await getClientSesmt().get(`matriz-config/${id}`);
        matrizConfig.value = data;
        definindoLinhas(matrizConfig.value.allLinhas);
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loadingMatriz.value = false;
    }
}

defineExpose({
    payload
});
</script>
<template>
    <Panel header="Matriz Avaliação" class="mt-5">
        <div class="flex grid">
            <div class="flex flex-column col">
                <label class="mb-2" for="dropdownMatrizes">Selecionar Matriz</label>
                <DropdownMatrizes id="dropdownMatrizes" v-model="matrizes" />
            </div>

            <div class="flex flex-column ml-3 col">
                <label v-if="matrizConfig?.matrizConfig?.nomeLinha" class="mb-2" for="linha">{{ matrizConfig?.matrizConfig?.nomeLinha }}</label>
                <div v-else class="mb-2">&nbsp;</div>
                <Dropdown
                    :disabled="!matrizes"
                    v-model="eixoX"
                    :options="eixosX"
                    optionLabel="titulo"
                    placeholder="Selecionar"
                    checkmark
                    :highlightOnSelect="false"
                    class="w-full"
                />
            </div>
            <div class="flex flex-column ml-3 col">
                <label class="mb-2" v-if="matrizConfig?.matrizConfig?.nomeColuna" for="coluna">{{ matrizConfig?.matrizConfig?.nomeColuna }}</label>
                <div v-else class="mb-2">&nbsp;</div>

                <Dropdown
                    :disabled="!matrizes"
                    v-model="eixoY"
                    :options="eixosY"
                    optionLabel="titulo"
                    placeholder="Selecionar"
                    checkmark
                    :highlightOnSelect="false"
                    class="w-full"
                />
            </div>
            <div class="flex flex-column ml-3 col">
                <label class="mb-2" v-if="matrizConfig?.matrizConfig?.nomeResultado" for="nivelRisco">{{
                    matrizConfig?.matrizConfig?.nomeResultado
                }}</label>
                <div v-else class="mb-2">&nbsp;</div>
                <InputText v-model="calculandoRisco.titulo" v-if="calculandoRisco?.titulo" disabled class="w-full" />
            </div>
        </div>
    </Panel>
</template>
