<script setup>
import { ref, watch, computed, defineExpose, defineProps } from 'vue';
import OrigemAnexoEnum from '../../../../../enums/OrigemAnexoEnum';
import TipoAnexoEnum from '../../../../../enums/TipoAnexoEnum';
import AnexarArquivos from '../../AnexarArquivos.vue';
import DialogImportarTextoMedidaAdmEngenharia from '../DialogImportarTextoMedidaAdmEngenharia.vue';
import AppInputTextLimitado from '@/components/input/AppInputTextLimitado.vue';
import MedidaControle from '../../../../../enums/MedidaControleEnum';

const props = defineProps({
    descricaoAdm: String,
    dataInclusao: String,
    id: Number
});

const { MedidaControleEnum } = MedidaControle;

const descricao = ref(props.descricaoAdm);
const dialogImportarTextoMedidaAdmEngenharia = ref();
const anexarArquivos = ref();

const openDialogImportarTextoMedidaAdmEngenharia = () => dialogImportarTextoMedidaAdmEngenharia.value?.openDialog();

watch(
    () => dialogImportarTextoMedidaAdmEngenharia.value?.texto,
    (newValue) => {
        if (!newValue) return;
        descricao.value = newValue;
    }
);

watch(
    () => props.descricaoAdm,
    () => {
        descricao.value = props.descricaoAdm;
    }
);

const payload = computed(() => {
    return {
        id: props.id,
        tipo: MedidaControleEnum.ADMINISTRATIVA,
        descricao: descricao.value,
        anexosTemporarios: anexarArquivos.value?.anexosTemporarios,
        dataInclusao: props.dataInclusao ?? new Date()
    };
});

defineExpose({
    payload
});
</script>
<template>
    <div class="flex flex-column w-full">
        <div class="flex flex-column w-full mt-4">
            <AppInputTextLimitado
                placeholder="Descrição"
                v-model="descricao"
                botaoCabecalho
                botaoLabel="Importar Texto"
                @onAcaoBotaoCabecalho="openDialogImportarTextoMedidaAdmEngenharia"
            />
        </div>

        <div class="w-full mt-4">
            <AnexarArquivos
                class="mt-5"
                ref="anexarArquivos"
                :habilitarAnexoTemporario="!props?.id"
                :origemId="props?.id"
                :origem="OrigemAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE"
                :tipo="TipoAnexoEnum.CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE"
            />
        </div>
        <DialogImportarTextoMedidaAdmEngenharia tipo="ADMINISTRATIVA" ref="dialogImportarTextoMedidaAdmEngenharia" />
    </div>
</template>
