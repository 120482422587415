<script setup>
import { defineProps, defineEmits, ref, watchEffect, computed } from 'vue';
import { useDialog } from 'primevue/usedialog';
import FormMedicaoRisco from '../form-medicao-risco/FormMedicaoRisco.vue';

const props = defineProps({
    risco: Object,
    aplicacaoPerigoFonteGeradoraMedicao: Object,
    index: Number,
    indexDescricaoRisco: Number
});
const fonteGeradoraMedicao = ref(props.aplicacaoPerigoFonteGeradoraMedicao);

watchEffect(() => {
    fonteGeradoraMedicao.value = props.aplicacaoPerigoFonteGeradoraMedicao;
});

const dialog = useDialog();
function openDialog() {
    dialog.open(FormMedicaoRisco, {
        props: {
            header: 'Medição de risco',
            style: {
                width: '80rem',
                overflow: 'hidden'
            },
            modal: true,
            blockScroll: true,
            closable: false
        },
        data: {
            risco: props.risco,
            aplicacaoPerigoFonteGeradoraMedicao: props.aplicacaoPerigoFonteGeradoraMedicao
        },
        onClose: (form) => {
            if (!form.data) {
                return;
            }

            emit('receberMedicao', { medicao: form.data, index: props.index, indexDescricaoRisco: props.indexDescricaoRisco });
        }
    });
}

const hasMedicao = computed(() => props.aplicacaoPerigoFonteGeradoraMedicao?.medicoesFonteGeradora?.length);

const emit = defineEmits(['receberMedicao']);
</script>
<template>
    <div class="flex-column col">
        <span class="title">Medição</span>
        <div class="flex p-3 mt-2 bg-gray-50 flex-column">
            <div class="flex justify-content-between">
                <Button v-if="hasMedicao" text icon="pi pi-pencil" label="Editar" @click="openDialog" />
                <Button v-if="!hasMedicao" text icon="pi pi-arrows-h" label="Medir risco" @click="openDialog" />
            </div>
        </div>
    </div>
</template>
