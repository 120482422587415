<script setup>
import { ref, defineProps, watch, defineExpose } from 'vue';

import FormMedidaEPI from './FormMedidasControle/FormMedidaEPI.vue';
import FormMedidaEPC from './FormMedidasControle/FormMedidaEPC.vue';
import FormMedidaAdministrativa from './FormMedidasControle/FormMedidaAdministrativa.vue';
import FormMedidaEngenharia from './FormMedidasControle/FormMedidaEngenharia.vue';
import MedidaControle from '../../../../enums/MedidaControleEnum';

const props = defineProps({
    aplicacaoPerigoMedidasControle: Array
});

const { MedidaControleEnum } = MedidaControle;

const aplicacaoPerigoMedidasControle = ref([
    {
        tipo: ''
    }
]);

const formMedidaEPC = ref();
const formMedidaEPI = ref();
const formMedidaAdministrativa = ref();
const formMedidaEngenharia = ref();
const aplicacaoPerigoMedidasControlePayload = ref([]);

watch(
    () => props.aplicacaoPerigoMedidasControle,
    () => {
        aplicacaoPerigoMedidasControle.value = props.aplicacaoPerigoMedidasControle;
    }
);

function addMedidaControle() {
    aplicacaoPerigoMedidasControle.value.push({
        tipo: ''
    });
}

function removerMedidaControle(index) {
    aplicacaoPerigoMedidasControle.value.splice(index, 1);
}

async function obterPayloadPainelMedidasControle() {
    aplicacaoPerigoMedidasControlePayload.value = [];

    for (const item of aplicacaoPerigoMedidasControle.value) {
        let novoItem = null;

        if (item.tipo === MedidaControleEnum.EPC && formMedidaEPC.value?.payloadEPC) {
            novoItem = formMedidaEPC.value?.payloadEPC;
        } else if (item.tipo === MedidaControleEnum.EPI && formMedidaEPI.value?.payloadEPI) {
            novoItem = formMedidaEPI.value?.payloadEPI;
        } else if (item.tipo === MedidaControleEnum.ADMINISTRATIVA && formMedidaAdministrativa.value?.payload) {
            novoItem = formMedidaAdministrativa.value?.payload;
        } else if (item.tipo === MedidaControleEnum.ENGENHARIA && formMedidaEngenharia.value?.payload) {
            novoItem = formMedidaEngenharia.value?.payload;
        }

        aplicacaoPerigoMedidasControlePayload.value.push(novoItem);
    }
}

defineExpose({
    obterPayloadPainelMedidasControle,
    aplicacaoPerigoMedidasControlePayload
});
</script>
<template>
    <div class="mt-4">
        <div class="w-full mt-5">
            <div>
                <span class="font-semibold line-height-3">Medidas de controle existentes</span>
                <div class="mt-5" v-for="(input, index) in aplicacaoPerigoMedidasControle" :key="index">
                    <Panel toggleable>
                        <template #header>
                            <div class="flex w-full justify-content-between align-items-center">
                                <div class="flex flex-wrap gap-3">
                                    <div class="flex align-items-center mr-3">
                                        <RadioButton v-model="input.tipo" inputId="epi" name="medida-controle" :value="MedidaControleEnum.EPI" />
                                        <label for="epi" class="ml-2">{{ MedidaControleEnum.EPI }}</label>
                                    </div>
                                    <div class="flex align-items-center mr-3">
                                        <RadioButton v-model="input.tipo" inputId="epc" name="medida-controle" :value="MedidaControleEnum.EPC" />
                                        <label for="epc" class="ml-2">{{ MedidaControleEnum.EPC }}</label>
                                    </div>
                                    <div class="flex align-items-center mr-3">
                                        <RadioButton
                                            v-model="input.tipo"
                                            inputId="medida-administrativa"
                                            name="medida-controle"
                                            :value="MedidaControleEnum.ADMINISTRATIVA"
                                        />
                                        <label for="medida-administrativa" class="ml-2">{{ MedidaControleEnum.ADMINISTRATIVA }}</label>
                                    </div>
                                    <div class="flex align-items-center mr-3">
                                        <RadioButton
                                            v-model="input.tipo"
                                            inputId="medida-engenharia"
                                            name="medida-controle"
                                            :value="MedidaControleEnum.ENGENHARIA"
                                        />
                                        <label for="medida-engenharia" class="ml-2">{{ MedidaControleEnum.ENGENHARIA }}</label>
                                    </div>
                                </div>
                                <Button label="Excluir medida" text icon="pi pi-trash" @click="removerMedidaControle(index)" />
                            </div>
                        </template>

                        <div>
                            <FormMedidaEPI
                                :dataInclusao="input?.dataInclusao"
                                :id="input?.id"
                                ref="formMedidaEPI"
                                v-if="input.tipo === MedidaControleEnum.EPI"
                                :epiId="input?.epiId"
                                :descricao="input?.descricao"
                                :certificado="input?.certificadoAprovacao"
                            />
                            <FormMedidaEPC
                                :dataInclusao="input?.dataInclusao"
                                :id="input?.id"
                                ref="formMedidaEPC"
                                v-if="input.tipo === MedidaControleEnum.EPC"
                                :epcId="input?.epcId"
                                :descricao="input?.descricao"
                            />

                            <FormMedidaAdministrativa
                                :id="input?.id"
                                :descricaoAdm="input?.descricao"
                                :dataInclusao="input?.dataInclusao"
                                ref="formMedidaAdministrativa"
                                v-if="input.tipo === MedidaControleEnum.ADMINISTRATIVA"
                            />
                            <FormMedidaEngenharia
                                :id="input?.id"
                                :descricaoEngenharia="input?.descricao"
                                :dataInclusao="input?.dataInclusao"
                                ref="formMedidaEngenharia"
                                v-if="input.tipo === MedidaControleEnum.ENGENHARIA"
                            />
                        </div>
                    </Panel>
                </div>

                <div>
                    <Button
                        class="button-salvar p-button p-button-outlined mt-3"
                        type="button"
                        icon="pi pi-plus"
                        label="Adicionar medida de controle existente"
                        @click="addMedidaControle"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
