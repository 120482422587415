<script setup>
import { ref, defineExpose, defineProps, watch } from 'vue';

import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const props = defineProps({
    lesoesOuDanos: String
});

const possiveisLesoesDanosSaude = ref(props.lesoesOuDanos);

const rules = {
    possiveisLesoesDanosSaude: {
        required: helpers.withMessage('Possíveis lesoes ou danos à saúde é obrigatório', required)
    }
};

const v$ = useVuelidate(rules, possiveisLesoesDanosSaude);

watch(
    () => props.lesoesOuDanos,
    () => {
        possiveisLesoesDanosSaude.value = props.lesoesOuDanos;
    }
);

defineExpose({ v$, possiveisLesoesDanosSaude });
</script>
<template>
    <div class="w-full mt-5">
        <div class="flex flex-column w-full">
            <label class="mb-2" for="descricaoRisco">Possíveis lesoes ou danos à saúde</label>
            <InputText
                :class="{ 'p-invalid': v$.possiveisLesoesDanosSaude.$errors.length }"
                v-model="possiveisLesoesDanosSaude"
                class="w-full input-large"
            />
            <small v-if="v$.possiveisLesoesDanosSaude.$errors.length" class="p-error">
                {{ v$.possiveisLesoesDanosSaude.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>
