<script setup>
import { ref, defineExpose, watch, defineProps } from 'vue';
import DropdownMedidaAdmEngenharia from './FormMedidasControle/DropdownMedidaAdmEngenharia.vue';

defineProps({
    tipo: String
});

const isOpen = ref(false);
const texto = ref();
const medidaAdmEngenharia = ref();

function openDialog() {
    isOpen.value = true;
}

function closeDialog() {
    isOpen.value = false;
}

watch(
    () => medidaAdmEngenharia.value,
    (newValue) => {
        texto.value = newValue?.descricao;
    }
);

defineExpose({
    openDialog,
    texto
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '450px' }" header="Importar texto" @hide="closeDialog" :draggable="false" modal>
        <div class="flex flex-column gap-2 w-full">
            <DropdownMedidaAdmEngenharia class="mb-4" v-model="medidaAdmEngenharia" :filtrosExtras="{ tipo }" />
            <InputText v-model="texto" disabled />
            <div class="flex justify-content-end align-items-center mt-4">
                <Button @click="closeDialog" class="button-link mb-1" text label="Cancelar" />
                <Button class="ml-4 button-salvar" type="button" :disabled="!medidaAdmEngenharia" label="Confirmar" @click="closeDialog" />
            </div>
        </div>
    </Dialog>
</template>

<style scoped lang="scss">
.input {
    width: 150px !important;
}
</style>
