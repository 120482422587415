<script setup>
import { ref, defineProps, watch, defineExpose, onMounted } from 'vue';

const props = defineProps({
    acoesNecessarias: Array
});

const inputAcoesNecessarias = ref([]);

watch(
    () => props.acoesNecessarias,
    () => {
        props.acoesNecessarias.forEach((item) => {
            inputAcoesNecessarias.value.push({ value: item });
        });
    }
);

function addAcao() {
    inputAcoesNecessarias.value.push({ value: '' });
}

function removeAcao(index) {
    inputAcoesNecessarias.value.splice(index, 1);
}

onMounted(() => {
    if (props.acoesNecessarias?.length > 0 && typeof props.acoesNecessarias === 'object') {
        props.acoesNecessarias.forEach((item) => {
            inputAcoesNecessarias.value.push({ value: item });
        });
    }
});
defineExpose({ inputAcoesNecessarias });
</script>
<template>
    <div class="flex flex-column w-full mt-4">
        <span class="title font-semibold line-height-3">Ações necessárias</span>
        <Message :closable="false">Para que seja possível controlar as ações de forma separada, crie um registro para cada uma delas.</Message>

        <span class="mb-4">Descreva a ação necessária</span>
        <div class="grupo-inputs w-full">
            <div class="flex flex-column w-full" v-for="(input, index) in inputAcoesNecessarias" :key="index">
                <div class="flex flex-column w-full mb-4">
                    <div class="flex align-items-center">
                        <Textarea autoResize v-model="input.value" class="w-full input-large" rows="1" col="10" />

                        <Button v-if="index >= 0" text rounded icon="pi pi-trash" @click="removeAcao(index)" />
                    </div>
                </div>
            </div>
            <div>
                <Button
                    class="button-salvar p-button p-button-outlined mt-3"
                    type="button"
                    icon="pi pi-plus"
                    label="Adicionar ação necessária"
                    @click="addAcao"
                />
            </div>
        </div>
    </div>
</template>
