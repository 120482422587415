<script setup>
import { computed, inject, ref } from 'vue';
import CardDadosRisco from './CardDadosRisco.vue';
import DropdownUnidadesMedida from '../../../riscos/components/DropdownUnidadesMedida.vue';
import DropdownEquipamentos from '../../../equipment/components/DropdownEquipamentos.vue';
import DropdownProfissionalSesmt from '../../../profissionais-sesmt/components/DropdownProfissionalSesmt.vue';
import FormRegistrarPendencia from '../FormRegistrarPendencia.vue';
import MedicaoGrid from './MedicaoGrid.vue';
import { useMoment } from '../../../../composables/useMoment';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const { formatDateBr } = useMoment();

const dialogRef = inject('dialogRef');
const dadosRisco = computed(() => dialogRef.value.data.risco);

const form = ref();
form.value = {
    ...dialogRef.value.data.aplicacaoPerigoFonteGeradoraMedicao //.medicoesFonteGeradora
};

const datasMedicoes = computed(() => {
    const medicoesFonteGeradora = form.value.medicoesFonteGeradora;

    if (!medicoesFonteGeradora?.length) return [];

    const dataInicial = new Date(formatDateBr(medicoesFonteGeradora[0].dataMedicao));
    const dataFinal = new Date(formatDateBr(medicoesFonteGeradora[medicoesFonteGeradora.length - 1].dataMedicao));

    return [dataInicial, dataFinal];
});

const rules = {
    tempoExposicao: {
        required: helpers.withMessage('O tempo de exposição é obrigatório.', required),
        lazy: true
    },
    unidadeMedida: {
        required: helpers.withMessage('A unidade de medida é obrigatória.', required),
        lazy: true
    },
    equipamento: {
        required: helpers.withMessage('O equipamento é obrigatório.', required),
        lazy: true
    },
    responsavelMedicao: {
        required: helpers.withMessage('O responsável pela medição é obrigatório.', required),
        lazy: true
    }
};
const v$ = useVuelidate(rules, form);

const mensagemMedicaoObrigatoria = computed(() => !form.value.medicoesFonteGeradora?.length);
async function adicionarMedicaoRisco() {
    const formularioIsValid = await validarFormulario();

    if (!formularioIsValid) return;

    if (mensagemMedicaoObrigatoria.value) {
        return;
    }

    atribuirValoresForm();

    dialogRef.value.close(form.value);
}

function atribuirValoresForm() {
    form.value.unidadeMedidaId = form.value.unidadeMedida.id;
    form.value.equipamentoId = form.value.equipamento.id;
    form.value.responsavelMedicaoId = form.value.responsavelMedicao.id;
}

async function validarFormulario() {
    const [tempoExposicaoIsValid, unidadeMedidaIsValid, equipamentoIsValid, responsavelMedicaoIsValid] = await Promise.all([
        v$.value.tempoExposicao.$validate(),
        v$.value.unidadeMedida.$validate(),
        v$.value.equipamento.$validate(),
        v$.value.responsavelMedicao.$validate()
    ]);

    return tempoExposicaoIsValid && unidadeMedidaIsValid && equipamentoIsValid && responsavelMedicaoIsValid;
}
</script>

<template>
    <div v-if="form" class="dialog-container">
        <CardDadosRisco :risco="dadosRisco" class="mb-5" />
        <div class="scrollable-content">
            <div class="field mb-6" v-if="form.medicaoNaoExecutada">
                <label for="justificativaFaltaMedicao">Justificativa de falta de medição</label>
                <InputText id="justificativaFaltaMedicao" v-model="form.justificativaFaltaMedicao" disabled class="w-full" />
            </div>

            <div class="grid">
                <div class="field col-4 pb-0">
                    <label for="tempoExposicao">Tempo de exposição (min)</label>
                    <InputNumber
                        id="tempoExposicao"
                        v-model="v$.tempoExposicao.$model"
                        :class="{ 'p-invalid': v$.tempoExposicao.$errors.length && v$.tempoExposicao.lazy.$dirty }"
                        class="w-full"
                    />
                    <small v-if="v$.tempoExposicao.$errors.length && v$.tempoExposicao.lazy.$dirty" class="p-error">
                        {{ v$.tempoExposicao.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-4 pb-0">
                    <label for="unidadeMedida">Unidade de medida</label>
                    <DropdownUnidadesMedida
                        id="unidadeMedida"
                        v-model="v$.unidadeMedida.$model"
                        :class="{ 'p-invalid': v$.unidadeMedida.$errors.length && v$.unidadeMedida.lazy.$dirty }"
                        :idValue="form.unidadeMedidaId"
                        class="w-full"
                        placeholder="Selecionar"
                    />
                    <small v-if="v$.unidadeMedida.$errors.length && v$.unidadeMedida.lazy.$dirty" class="p-error">
                        {{ v$.unidadeMedida.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-4 pb-0">
                    <label for="dataMedicao">Data da medição</label>
                    <Calendar
                        id="dataMedicao"
                        class="w-full"
                        dateFormat="dd/mm/yy"
                        selectionMode="range"
                        :manualInput="false"
                        :modelValue="datasMedicoes"
                        showIcon
                        disabled
                    />
                </div>
                <div class="field col-6 pt-0">
                    <label for="equipamento">Equipamento</label>
                    <DropdownEquipamentos
                        id="equipamento"
                        v-model="v$.equipamento.$model"
                        :class="{ 'p-invalid': v$.equipamento.$errors.length && v$.equipamento.lazy.$dirty }"
                        :idValue="form.equipamentoId"
                        class="w-full"
                    />
                    <small v-if="v$.equipamento.$errors.length && v$.equipamento.lazy.$dirty" class="p-error">
                        {{ v$.equipamento.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-6 pt-0">
                    <label for="responsavelMedicao">Responsável pela medição</label>
                    <DropdownProfissionalSesmt
                        id="responsavelMedicao"
                        v-model="v$.responsavelMedicao.$model"
                        :class="{ 'p-invalid': v$.responsavelMedicao.$errors.length && v$.responsavelMedicao.lazy.$dirty }"
                        :idValue="form.responsavelMedicaoId"
                        :filtrosExtras="null"
                        class="w-full"
                    />
                    <small v-if="v$.responsavelMedicao.$errors.length && v$.responsavelMedicao.lazy.$dirty" class="p-error">
                        {{ v$.responsavelMedicao.$errors[0].$message }}
                    </small>
                </div>
            </div>
            <MedicaoGrid v-model:medicoesFonteGeradora="form.medicoesFonteGeradora" :risco="dadosRisco" :unidadeMedida="form.unidadeMedida" />
            <FormRegistrarPendencia ref="pendencia" class="mt-3" v-if="form.id" :origemId="form.id" origem="PERIGO_FONTE_GERADORA_MEDICAO" />
            <Message v-if="mensagemMedicaoObrigatoria" severity="info">Necessário informar ao menos uma medição.</Message>
        </div>
    </div>

    <div class="footer-buttons">
        <Button class="button-link" @click="() => dialogRef.close()" text label="Cancelar" />
        <Button class="ml-5 button-salvar" type="button" label="Adicionar" @click="adicionarMedicaoRisco" />
    </div>
</template>

<style scoped="scss">
.dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 70vh;
}

.scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.footer-buttons {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
}
</style>
